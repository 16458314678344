<template>
	<div id="customerConsultation">
		<div class="customerPhone">
      <div class="customerPhoneText">客服咨询</div>
      <a :href="'tel:' + customerData.customerPhone" class="phoneNum">{{customerData.customerPhone1}}</a>
    </div>
    <div class="specialistPhoneTitle">车管家及电话</div>
    <div class="specialistPhone" v-for="(v,i) in customerData.speciaPhonelist" :key='i'>
      <div class="specialistPhoneOne" v-if='v.type == 0' >
        <div class="customerPhoneText">{{v.name}}</div>
        <a :href="'tel:' + v.phone" class="phoneNum">{{v.phone1}}</a>
      </div>
    </div>
	</div>
</template>

<script>
import { validApp, getUrlParam, setCookie, getCookie, delCookie } from '../../lib/utils';
import { customerConsultation } from '@/server/request'
import { Dialog, Toast } from 'vant';

export default {
	data() {
		return {
			customerData:{
        specialistPhone:[]
      }
		}
	},
	created() {
    document.title = '客服咨询'
    let params = ''
		customerConsultation(params).then(res=>{
			if(res.status=='200'){
        this.customerData = res.data
        for(var i = 0 ; i < this.customerData.speciaPhonelist.length ; i++){
          if(this.customerData.speciaPhonelist[i].type == 1){
            this.customerData.customerPhone = this.customerData.speciaPhonelist[i].phone
            this.customerData.customerPhone1 = this.strInsert(this.customerData.customerPhone)
          }else{
            this.customerData.speciaPhonelist[i].phone1 = this.strInsert(this.customerData.speciaPhonelist[i].phone)
          }
        }
			}else{
				Toast.fail({
					message: res.msg,
					duration:'3000'
				});
			}
		})
	},
	mounted() {
		
	},
	computed: {
		
	},
	methods: {
    strInsert(str) {
      let reg = new RegExp("\\d{1,4}", "g");
      let ma = str.match(reg);
      return ma.join("-");
    }
  },
};
</script>

<style>
html,body{
  height: 100%;
}
#app{
  height: 100%;
}
#customerConsultation{
  background-color: #F5F5F5;
  padding-top: 0.3rem;
}
.customerPhone{
  height: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.4rem;
  background-color: #ffffff;
}
.customerPhoneText{
  font-size: 0.3rem;
  color: #333333;
}
.phoneNum{
  color: #1A5DD5;
  font-size: 0.3rem;
  font-weight: bold;
}
.specialistPhoneTitle{
  padding-top: 0.44rem;
  padding-left: 0.4rem;
  padding-bottom: 0.1rem;
  color: #989898;
  font-size: 0.26rem;
}
.specialistPhone{
  background-color: #ffffff;
}
.specialistPhoneOne{
  margin-left: 0.4rem;
  border-bottom: 1px solid #EEEEEE;
  height: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.4rem;
}
.specialistPhoneOne:last-child{
  border-bottom: none;
}
</style>
